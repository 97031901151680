.user-container {
  position: relative;
}

.user-icon-container {
  padding: 1.5em 1.3125em;
}

.user-icon {
  background-color: #aaa;
  border-radius: 50%;
  width: 2.375em;
  height: 2.375em;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  position: relative;
  z-index: 10;
}

.user-name {
  background-color: #eeeff0;
  border-radius: 50%;
  font-size: 0.875em;
  color: #626279;
  cursor: pointer;
  font-family: Arial;
  letter-spacing: 0.1428571429em;
  line-height: 2.7142857143;
  height: 2.7142857143em;
  width: 2.7142857143em;
  text-align: center;
  position: relative;
  z-index: 10;
}

.user-panel {
  background-color: #fff;
  border-radius: 3px;
  min-width: 12.5em;
  width: auto;
  position: absolute;
  top: 1em;
  left: 0.8125em;
  z-index: 5;
}

.user-panel .user-full-name {
  font-size: 1.125em;
  color: #354052;
  margin-left: 3.25em;
  padding: 1em 0;
  display: inline-block;
  max-width: 19em;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  margin-right: 0.5em;
}

.user-panel .user-logout:hover {
  color: #354052;
}

.user-panel .user-logout:hover svg path {
  fill: #354052;
}

.user-panel .user-logout svg {
  vertical-align: middle;
  margin-right: 0.5em;
}

.user-panel .user-logout {
  appearance: none;
  -webkit-appearance: none;
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  font-size: 1em;
}

.user-panel .user-logout {
  border-top: 1px solid #eeeff0;
  padding: 1em 0.5em;
  color: #8f91a0;
  width: 100%;
}
