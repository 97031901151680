.toasts {
  bottom: 6em;
  position: fixed;
  right: 1.5em;
  width: 32em;
  z-index: 100000;
  display: flex;
  flex-direction: column-reverse;
  padding: 0;
}

.toast {
  align-items: flex-start;
  border-radius: 4px;
  color: #ffffff;
  display: flex;
  padding: 1em;
  transition: all 0.5s;
  transform: translateX(200em) scale(1);
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.3);
}

.toast:not(:first-child) {
  margin: 0 0 0.75em;
}

.toast-content {
  flex: 1 1 auto;
  margin: 0 0.75em 0 0;
}

.toast-dismiss {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  border: 0;
  color: inherit;
  cursor: pointer;
  display: block;
  flex: 0 0 auto;
  font: inherit;
  padding: 0;
}

.toast-close-icon {
  vertical-align: middle;
}

.toast-exit {
  transform: scale(1);
  transition: transform 500ms ease-in;
}

.toast-exit.toast-exit-active {
  transform: scale(0);
  transition: transform 500ms ease-in;
}

.toasts .warning,
.toasts .info,
.toasts .error,
.toasts .success,
.toasts .progress {
  position: relative;
  transform: translateX(0);
}

.toasts .warning .toast-content,
.toasts .info .toast-content,
.toasts .error .toast-content,
.toasts .success .toast-content {
  margin-left: 1.75em;
}

.toasts .warning::before,
.toasts .info::before,
.toasts .error::before,
.toasts .success::before {
  position: absolute;
  top: 1em;
}

.toasts .warning {
  background-color: #f8a848;
}

.toasts .warning::before {
  content: url('warning_icon.png');
}

.toasts .info {
  background-color: #78cfe6;
}

.toasts .info::before {
  content: url('info_icon.svg');
}

.toasts .error {
  background-color: #ff7171;
}

.toasts .error::before {
  content: url('warning_icon.png');
}

.toasts .success {
  background-color: #89cc89;
}

.toasts .success::before {
  content: url('./../search/action/check.svg');
}

.toasts .progress {
  background-color: #78cfe6;
  padding: 0;
}

/** ### Type Progress Styling
*/
.toasts .progress-bar {
  display: block;
  margin: 0;
  width: 100%;
  height: 3em;
  border-radius: 4px;
  background: #4b4b61;
  position: relative;
  text-align: center;
}

.progress-value {
  position: relative;
  z-index: 5;
  line-height: 3em;
  vertical-align: middle;
}

.toasts .progress-status {
  background-color: #89cc89;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.toasts .toast-dismiss {
  position: absolute;
  right: 1em;
  top: 1em;
}
