main {
  height: 100%;
  display: flex;
}

main,
input,
textarea,
button,
select {
  font-family: 'Proxima Nova' sans-serif !important;
}
nav {
  display: flex;
  align-items: center;
  flex-flow: column;
  background-color: #626279;
  width: 5em;
  position: relative;
}

nav ul {
  margin: unset;
  padding: unset;
  list-style-type: none;
  width: 100%;
  text-align: center;
}

nav ul .active {
  background-color: rgba(63, 63, 87, 0.3);
}

.tool-option {
  display: block;
  padding: 1.4375em 1.5625em;
}

.tool-option img {
  height: 1.75em;
}

.liveby-logo {
  width: 2.5em;
  margin: auto 0 1.25em;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 1001;
}
