*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family:
    Proxima Nova,
    sans-serif;
}

:root {
  --gray: #ccc;
  --primary: #4b4b61;
  --secondary: #626279;
  --warning-color: orange;
  --success-background: #edf9f0;
  --success-color: #287d3c;
  --info-background: #eef2fa;
  --info-color: #2e5aac;
  --error-background: #feefef;
  --error-color: #da1414;
}
